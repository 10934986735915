import { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import LocaleContext from '../contexts/locale';

const all = [];
const uuidMap = {};
const localeMap = {};

export const useJobByUuid = (uuid) => {
	invokeQueryAndMapData();

	if (uuid in uuidMap) {
		return uuidMap[uuid];
	}

	return null;
};

const invokeQueryAndMapData = () => {
	const {
		allMarkdownRemark: {
			edges: jobs
		}
	} = useStaticQuery(query);

	const {
		currentLocale
	} = useContext(LocaleContext);

	const mapItem = ({
		node: {
			frontmatter: data,
			fields
		}
	}) => ({
		...data,
		...fields
	});

	if (all.length === 0) {
		for (let job of jobs) {
			const item = mapItem(job);
			const { uuid, locale } = item;

			localeMap[locale] = localeMap[locale] || [];
			localeMap[locale].push(item);
			uuidMap[uuid] = item;
			all.push(item);
		}
	}

	return localeMap[currentLocale] || [];
};

export default invokeQueryAndMapData;

const query = graphql`
	query useJobs {
		allMarkdownRemark(
	    	filter: {
		        frontmatter: {
		          type: {
		            eq: "job"
		          }
		        }
		  	}
		  	sort: {
		  		fields: frontmatter___name
		  	}
	  	)
	  	{
			edges {
				node {
					frontmatter {
						uuid
						location
						position
						specification
					}
					fields {
						slug
						locale
					}
				}
			}
		}
	}
`;