import { graphql } from 'gatsby';

import { Page } from '../components/layout';

import List from './jobs/list';

// import * as styles from './jobs.module.scss';

const Brands = ({
	data: {
		markdownRemark: {
			frontmatter: { message },
			fields: { slug },
			html,
		},
	},
}) => (
	<Page slug={slug} body={html}>
		<List>{message}</List>
	</Page>
);

export const jobsQuery = graphql`
	query jobsQuery($remarkId: String!) {
		markdownRemark(id: { eq: $remarkId }) {
			frontmatter {
				message
			}
			fields {
				slug
			}
			html
		}
	}
`;

export default Brands;
