import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import useJobs from '../../hooks/use-jobs';

import * as styles from './list.module.scss';

//
// LETTER
//
export const Job = ({ job: { slug, location, position, specification } }) => (
	<Link to={slug} className={styles.item}>
		<h2 className={styles.header}>{location}</h2>

		<div>
			<strong className={styles.position}>{position}</strong>
			<span>{specification}</span>
		</div>
	</Link>
);

Job.defaultProps = {
	job: null,
};

Job.propTypes = {
	job: PropTypes.shape({
		slug: PropTypes.string.isRequired,
		location: PropTypes.string.isRequired,
		position: PropTypes.string.isRequired,
		specification: PropTypes.string.isRequired,
	}).isRequired,
};

//
// WRAPPER
//
const Wrapper = ({ children }) => {
	const jobs = useJobs();

	return jobs.length > 0 ? (
		jobs.map((job, key) => <Job key={key} job={job} />)
	) : (
		<Fragment>{children}</Fragment>
	);
};

export default Wrapper;
